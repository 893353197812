import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/completedStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCompletedExamples() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>Coming Soon...</h2>
            <h4>
              To known about Uttar Pradesh we need to travel back to 4000 years
              ago, when the Aryans first made it their home in 2000 BC. This
              heralded the Vedic age of the Indian civilisation and Uttar
              Pradesh was its home. The Aryans first inhabited the Doaab region
              and the Ghagra plains and called it the Madhya Desha (midland) or
              Aryavarta (the Aryan land) or Bharatvarsha (the kingdom of Bharat,
              an important Aryan king). In the ages to come, Aryans moved to
              other parts of the Indian subcontinent, reaching as far as to
              Kerala and Sri Lanka in the south.
              <br />
              <b>Select category to know more...</b>
            </h4>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
