import React from "react";
import { Container, Row, Col } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import historyImg from "assets/img/gorakhnath.jpg";
import agricultureImg from "assets/img/agriculture.jpg";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/custom.css';
// import 'assets/jss/material-kit-react/views/componentsSections/loginStyle.js';

const useStyles = makeStyles({
  root: {
    width: 300,
    margin: 15,
    transition: "0.2s",
    textAlign: "center",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 3px 6px 0 gray",
    },
  },
  media: {
    height: 140,
  },
  parentdiv: {
    display: 'flex', 
    justifyContent: 'center'
  }
});

export default function Category() {
  const classes = useStyles();

  return (
    <Container>
      <Row xs={1} md={3} >
        <Col>
        <div className={classes.parentdiv}>
          <Card className={classes.root} spacing={2}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={historyImg}
                title="History"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  History
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  History are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions>
          </Card>
          </div>
        </Col>
        
        <Col>
        <div className={classes.parentdiv}>
          <Card className={classes.root} spacing={2}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={agricultureImg}
                title="Agriculture"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Agriculture
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Agriculture are a widespread group of squamate reptiles, with
                  over 6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions>
          </Card>
          </div>
        </Col>

        <Col>
        <div className={classes.parentdiv}>
          <Card className={classes.root} spacing={2}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={historyImg}
                title="History"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  History
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  History are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions>
          </Card>
          </div>
        </Col>
        
        <Col>
        <div className={classes.parentdiv}>
          <Card className={classes.root} spacing={2}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={agricultureImg}
                title="Agriculture"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Agriculture
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Agriculture are a widespread group of squamate reptiles, with
                  over 6,000 species, ranging across all continents
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions>
          </Card>
          </div>
        </Col>

        <Col>
        <div className={classes.parentdiv}>
          <Card className={classes.root} spacing={2}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={agricultureImg}
                title="Agriculture"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Agriculture
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Agriculture are a widespread group of squamate reptiles, with
                  over 6,000 species, ranging across all continents
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                Share
              </Button>
              <Button size="small" color="primary">
                Learn More
              </Button>
            </CardActions>
          </Card>
          </div>
        </Col>
        
      </Row>
    </Container>
  );
}
