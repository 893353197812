import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/signupPage.js";

import image from "assets/img/bg7.jpg";
import axios from 'axios'

const useStyles = makeStyles(styles);
const {useState} = React;

function changeFullName(event) {
  this.setState({
    fullName: event.target.value
  })
}

function changeEmail(event){
  this.setState({
    eMail: event.target.value
  })
}

function changePassword(event){
  this.setState({
    password: event.target.value
  })
}

export default function SignupPage(props) {

  const [fullName, changeFullName] = useState();
  const [email, changeEmail] = useState();
  const [password, changePassword] = useState();
  const [logResult, changelogResult] = useState("");

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();
  const { ...rest } = props;

  function onSubmit(event) {
    event.preventDefault(event)
    const registred = {
      fullName: fullName,
      eMail: email,
      password: password
    }
    axios.post('http://localhost:5000/app/signup', registred)
    .then(res => {
      console.log("Response: ", res.data);
      if(res.data){        
        changelogResult("Success")
      } else{
        changelogResult("Fail")
      }      
    })
  }

  return (
    <div>
      {/* <Header
        absolute
        color="transparent"
        brand="Mera Uttar"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}> */}
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} >
              <Card className={classes[cardAnimaton]}>

                <form className={classes.form} onSubmit={onSubmit}  >
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Sign Up</h4>
                    <div className={classes.socialLine}>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <i className={"fab fa-twitter"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <i className={"fab fa-facebook"} />
                      </Button>
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"
                        onClick={e => e.preventDefault()}
                      >
                        <i className={"fab fa-google-plus-g"} />
                      </Button>
                    </div>
                  </CardHeader>
                  <p className={classes.divider}>Get registered with us</p>
                  <CardBody>
                    {/* <p>FullName: {fullName}</p> 
                    <p>email: {email}</p> 
                    <p>password: {password}</p>  */}
                    <CustomInput
                      labelText="Full Name..."
                      id="fullName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => changeFullName(e.target.value),
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        )                        
                      }}        
                      value = {fullName}
                    />
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => changeEmail(e.target.value),
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                      value = {email}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => changePassword(e.target.value),
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                      value = {password}
                    />
                  </CardBody>
                  <p>{logResult}</p>
                  <CardFooter className={classes.cardFooter}>
                    <Button simple color="primary" size="lg"  type="submit"> 
                      Get started
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        {/* </div> */}
        {/* <Footer whiteFont /> */}
      {/* </div> */}
    </div>
  );
}
