import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// @map Leaflet
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from "react-leaflet";
import 'leaflet/dist/leaflet.css';


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/completedStyle.js";
import { Map } from "leaflet";

const useStyles = makeStyles(styles);

export default function SectionMap() {
  const [geoJSON, setgeoJSON] = React.useState(null);
  const classes = useStyles();

  Leaflet.Icon.Default.imagePath = '../node_modules/leaflet'
  delete Leaflet.Icon.Default.prototype._getIconUrl;
  Leaflet.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  });



    var url = `https://nominatim.openstreetmap.org/details.php?osmtype=R&osmid=1942587&class=boundary&addressdetails=1&hierarchy=0&polygon_geojson=1&group_hierarchy=1&format=json`
      fetch(url).then(function(response) {
      return response.json();
    })
    .then(function(json) {
      var geojsonFeature = json.geometry;
      //Leaflet.geoJSON(geojsonFeature).addTo(window.Map);
      setgeoJSON(geojsonFeature);
    });


  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <MapContainer
              center={[26.8467, 80.9462]}
              zoom={6}
              scrollWheelZoom={false}
              style={{height : '500px'}}
            >
              <TileLayer
                attribution=''
                url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
                id="mapbox/streets-v11"
                accessToken="sk.eyJ1IjoiZXItcmFodWxtYXVyeWEiLCJhIjoiY2txMGVkcmdpMDQzbTJ2cWlqcnk2NDRmZyJ9.jMwevOenK3-xZBWi4Sjp5w"
                 />
                 {geoJSON && (
                  <GeoJSON
                    attribution="Uttar Pradesh"
                    data={geoJSON}
                  />
                )}
              <Marker position={[26.8467, 80.9462]}>
                <Popup>
                  DevRashtra. <br /> Pleace of Gods.
                </Popup>
              </Marker>
            </MapContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
