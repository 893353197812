import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import People from "@material-ui/icons/People";
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/loginStyle.js";
import axios from 'axios'

const useStyles = makeStyles(styles);
const {useState} = React;

export default function SectionLogin() {
  const classes = useStyles();

  const [email, changeEmail] = useState();
  const [password, changePassword] = useState();
  const [logResult, changelogResult] = useState("");

  function onSubmit(event) {
    event.preventDefault(event)
    const logged = {
      eMail: email,
      password: password
    }
    console.log("logged: ", logged);
    axios.get('http://localhost:5000/app/signin', {
      params: {
        eMail: email,
        password: password
      }
    })
    .then(res => {
      console.log("Response: ", res.data);
      if(res.data){        
        changelogResult("Success")
      } else{
        changelogResult("Fail")
      }      
    })
  }

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            
           </GridItem>
         </GridContainer>
       </div>
     </div>
  );
}
